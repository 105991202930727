import { configureStore, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

const userSlice = createSlice({
  name: "user",
  initialState: {
    prenom: "",
    nom: "",
    email: "",
    access_token: "",
    is_auth: false,
    roles: [],
  },
  reducers: {
    addUser: (state, action) => {
      // console.log(action)
      const token = action.payload.token;
      state.access_token = token;
      const jwtdecode = jwtDecode(token);
      console.log(jwtdecode)
      state.email = "jwtdecode.username";
      state.roles = jwtdecode.roles;
      state.is_auth = true;
      localStorage.setItem("token", token);
      return state;
    },
    hasUser: (state, action) => {
      const token = localStorage.getItem("token");
      // Si le token est présent dans le stockage local

      if (token) {
        try {
          const jwtdecode = jwtDecode(token);

          // Si le décodage du token réussit
          if (jwtdecode) {
            state.email = jwtdecode.sub;
            state.roles = jwtdecode.roles;
            state.is_auth = true;
            return state;
          } else {
            // Gérer le cas où le décodage du token a échoué
            return { ...state, is_auth: false };
          }
        } catch (e) {
          // Gérer les erreurs potentielles liées au décodage du token
          return { ...state, is_auth: false };
        }
      }

      // Gérer le cas où le token est absent du stockage local
      return { ...state, is_auth: false };
    },
    logout : (state, action) => {
      localStorage.removeItem("token")
      return {
        prenom: "",
        nom: "",
        email: "",
        access_token: "",
        is_auth: false,
        roles: [],
      }
    }
  },
});

export const { addUser, hasUser, logout } = userSlice.actions;

export const userStore = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
});
