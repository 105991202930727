import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Btn1 from "../../components/buttons/button1";
import { Alert } from "@mui/material";

function Abonnement() {
  const user = useSelector((state) => state.user);
  const [abonnements, setAbonnements] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(
        process.env.REACT_APP_BASE_API +
          process.env.REACT_APP_ABONNEMENT +
          user.email,
        config
      )
      .then((res) => {
        console.log(res.data);
        setAbonnements(res.data);
      });
  }, []);
  return (
    <div className="abonnement">
      <div className="entete">
        <h4>Mes abonnements</h4>
        <Btn1
          href="/dashboard/mes-abonnements/new"
          text="Obtenir un abonnement"
        />
      </div>
      <div className="tableau">
        {abonnements.length > 0 ? (
          <div className="table-responsive">

          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Date Début</th>
                <th scope="col">Date Fin</th>
                {/* <th scope="col">Durée</th> */}
                <th scope="col">Statut</th>
              </tr>
            </thead>
            <tbody>
              {abonnements.map((abonnement, index) => (
                <tr>
                  <th scope="row">{index + 1} </th>
                  <td>{abonnement?.datedebut}</td>
                  <td>{abonnement?.datefin}</td>
                  {/* <td>{abonnement?.typeAbonnement === "Salarie" ? "Téraalma" : "Téraalma Max"} </td> */}
                  <td>
                    {abonnement.statut ? (
                      <button className="btn btn-ok">En cours</button>
                    ) : (
                      <button className="btn btn-false">Expiré</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        ) : (
          <Alert severity="warning">Vous n'avez aucun abonnement</Alert>
        )}
      </div>
    </div>
  );
}

export default Abonnement;
