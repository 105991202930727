import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import TitleSection from '../titleSection';
import Uploadimage from '../../../components/uploadImg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function NewPartenaire() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()
      const navigate  = useNavigate()
      const [logo, setLogo] = useState()
      const onSubmit = (data) => {
        const formData = new FormData()
        formData.append("nom", data.nom)
        formData.append("adresse", data.address)
        formData.append("telephone", data.tel)
        formData.append("email", data.email)
        formData.append("logo", logo)

        const configg = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

        axios.post(
            process.env.REACT_APP_BASE_API +
              process.env.REACT_APP_PARTENAIRE_NEW,
             formData, configg
          ).then((res) => {
            navigate("/dashboard/partenaires")
          })
      }
    return ( 
        <div className="new-partenaire">
            <TitleSection text='Ajouter un partenaire' textBtn='retour' href='/dashboard/partenaires' />
            <div className="content">
                <div className="imgUpload mt-5">
                    <Uploadimage setLogo={setLogo}/>
                    <div className="explication text-center">
                        <h6>Télécharger une image</h6>
                        <p>Supports: *.png, *.jpg, *.jpeg</p>
                    </div>
                </div>

                <div className="form mt-5">
                    <form  onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                        <label htmlFor="nom">Nom du partenaire <span className='text-danger'><sup>*</sup></span></label>
                        <input type="text" name='nom' className="form-control"  {...register("nom" , { required: true })} />
                        </div>
                        <div className="col-md-6 mb-4">
                        <label htmlFor="tel">Téléphone <span className='text-danger'><sup>*</sup></span></label>
                        <input type="text" name='tel' className="form-control"  {...register("tel" , { required: true })} />
                        </div>
                        <div className="col-md-6 mb-4">
                        <label htmlFor="email">Email <span className='text-danger'><sup>*</sup></span></label>
                        <input type="text" name='email' className="form-control"  {...register("email" , { required: true })} />
                        </div>
                        <div className="col-md-6 mb-4">
                        <label htmlFor="address">Adresse <span className='text-danger'><sup>*</sup></span></label>
                        <input type="text" name='address' className="form-control"  {...register("address" , { required: true })} />
                        </div>
                        <div className="col-md-12 text-end">
                        <button className="btn btn-1" type='submit'>Ajouter</button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
     );
}

export default NewPartenaire;