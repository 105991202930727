import React, { useEffect } from 'react'
import Btn1 from '../buttons/button1';
import Btn2 from '../buttons/button2';

function HeaderSectionn() {
    useEffect(() => {
        const smiley = document.getElementById('smiley');
        smiley.addEventListener('mouseenter', function() {
            this.classList.add('blink');
        });
        smiley.addEventListener('mouseleave', function() {
            this.classList.remove('blink');
        });
    }, [])
    
    return ( 
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="text">
                        <div className="title">
                            <h1>
                            Avec Teraalma <br /> <span> profitez sans <br /> vous limiter !</span>
                            </h1>
                            <div className="smill">
                                <svg id="smiley" xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136" fill="none">
                                    <path d="M67.75 135.5C105.167 135.5 135.5 105.167 135.5 67.75C135.5 30.3327 105.167 0 67.75 0C30.3327 0 0 30.3327 0 67.75C0 105.167 30.3327 135.5 67.75 135.5Z" fill="#FFAC00"/>
                                    <path class="eye" d="M45 64C48.3137 64 51 61.3137 51 58C51 54.6863 48.3137 52 45 52C41.6863 52 39 54.6863 39 58C39 61.3137 41.6863 64 45 64Z" fill="black"/>
                                    <path class="eye eye-right" d="M92 64C95.3137 64 98 61.3137 98 58C98 54.6863 95.3137 52 92 52C88.6863 52 86 54.6863 86 58C86 61.3137 88.6863 64 92 64Z" fill="black"/>
                                    <path class="mouth mouth-open" d="M31.96 84.11C35.7022 89.2894 40.6339 93.4941 46.34 96.37C51.945 99.1651 58.0945 100.698 64.3557 100.86C70.6169 101.023 76.8376 99.8108 82.58 97.31C88.4291 94.7337 93.5735 90.7896 97.58 85.81C97.6023 85.7876 97.6288 85.7699 97.6579 85.7577C97.6871 85.7456 97.7184 85.7394 97.75 85.7394C97.7816 85.7394 97.8129 85.7456 97.842 85.7577C97.8712 85.7699 97.8977 85.7876 97.92 85.81C97.961 85.8446 97.9892 85.8921 98 85.9446C98.0109 85.9972 98.0038 86.0519 97.98 86.1C94.8462 92.0993 89.9404 96.9876 83.93 100.1C77.8771 103.287 71.108 104.869 64.2696 104.694C57.4311 104.519 50.7518 102.593 44.87 99.1C39.0312 95.6804 34.3846 90.5487 31.56 84.4C31.5367 84.3416 31.5373 84.2764 31.5616 84.2184C31.586 84.1605 31.632 84.1143 31.69 84.09C31.7313 84.0643 31.7797 84.0525 31.8282 84.056C31.8767 84.0596 31.9229 84.0785 31.96 84.11Z" fill="black"/>
                                    <path class="mouth mouth-closed d-none" d="M58 87C58 90.3137 60.6863 93 64 93C67.3137 93 70 90.3137 70 87H58Z" fill="black" />
                                </svg>

                            </div>
                        </div>
                        <div className="description">
                            <p>
                            Teraalma c’est des réductions et des offres exclusives selectionnées pour vous par <span className="text-primary">Happyness Ambassadors</span>.
                            </p>
                            <div className="buttons">
                                <Btn1 href="/carte-virtuelle" text="Obtenir ma carte" displayIcon={true} />
                                <Btn2 href="/contact" text="Contactez-nous" />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="img">
                        <img src="/img/carte1.png" alt="header img" />
                    </div>
                </div>
            </div>
        </div>
     );
}

export default HeaderSectionn;