import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChoixOffre from './choixOffre';
import Condition from './conditions';
import { redirect } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

const steps = ['Chosir une offre', "Accepter les conditions d'utilisation", 'Paiement'];

export default function NewAbonnement() {
  const user = useSelector((state) => state.user);
  const [options, setOptions] = React.useState({
    dureeAbonnement : null,
    condition : false
  }) 
  console.log(options)
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const saveAbonnement = () => {
    const configg = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(
        process.env.REACT_APP_BASE_API +
          process.env.REACT_APP_ABONNEMENT_NEW +
          user.email,
         {dureeAbonnement : options.dureeAbonnement}, configg
      )
      .then((res) => {
        // console.log(res.data); 
        window.location.href = res.data.paydunya
      })
      .catch((error) => console.log(error))
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps} >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? 
        // <React.Fragment>
        //   <Typography sx={{ mt: 2, mb: 1 }}>
        //     All steps completed - you&apos;re finished
        //   </Typography>
        //   <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        //     <Box sx={{ flex: '1 1 auto' }} />
        //     <Button onClick={handleReset}>Reset</Button>
        //   </Box>
        // </React.Fragment>
        saveAbonnement()
        : (
        activeStep === 0 ?
        <>
        <ChoixOffre options={options} setOptions={setOptions} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Retour
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <button className='btn btn-1' onClick={handleNext} disabled={options.type === null} >
              {activeStep === steps.length - 1 ? 'Passer au paiement' : 'Suivant'}
            </button>
          </Box>
        </>
        :
        activeStep === 1 ?
        <>
        <Condition options={options} setOptions={setOptions} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Retour
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <button className='btn btn-1' onClick={handleNext} disabled={options.condition ===false}>
              {activeStep === steps.length - 1 ? 'Passer au paiement' : 'Suivant'}
            </button>
          </Box>
        </>
        :
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Nous allons vous rediriger vers la page de paiement. </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Retour
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <button className='btn btn-1' onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Passer au paiement' : 'Suivant'}
            </button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

const step1 = (
    <>
    <div className="bg-success d-flex">
            <h3>Téralma </h3>
            <h3>Téralma Max</h3>
    </div>
    </>
)