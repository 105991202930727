import React, { useEffect, useState } from 'react'
import TitleSection from '../titleSection';
import axios from 'axios';
import { Alert } from 'react-bootstrap';

function IndexPartennaire() {
    const [partenaires, setPartenaires] = useState([])
    const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
    useEffect(() => {
        axios.get(
            process.env.REACT_APP_BASE_API +
              process.env.REACT_APP_PARTENAIRE_LISTE, config
          ).then((res) => {
            console.log(res.data)
            setPartenaires(res.data)
          })

    }, [])
    return ( <div className="part">
        <TitleSection text='Liste des partenaires' textBtn='Ajouter' href='/dashboard/partenaires/new' /> 

        <div className="tableau  mt-5">
        {partenaires?.length > 0 ? (
          <div className="table-responsive">
          <table class="table table-striped  table-hover ">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">image</th>
                <th scope="col">Nom </th>
                <th scope="col">Email</th>
                <th scope="col">Tél</th>
                <th scope="col">Adresse</th>
              </tr>
            </thead>
            <tbody>
              {partenaires?.map((item, index) => (
                <tr>
                  <th scope="row">{index + 1} </th>
                  <td><img src={process.env.REACT_APP_BASE_API+"images/"+item.logo} alt="" width="50px" /></td>
                  <td>{item?.nom}</td>
                  <td>{item?.email} </td>
                  <td>{item?.telephone} </td>
                  <td>{item?.adresse} </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        ) : (
          <Alert severity="warning">Aucun  partenaire</Alert>
        )}
      </div>
    </div> );
}

export default IndexPartennaire;