import React, { useEffect } from 'react'
import Btn1 from '../buttons/button1';
import { scroll } from "framer-motion/dom";
import { Link, useLocation } from 'react-router-dom';

function Footer() {
    const progressWheel = document.querySelector(".progress");
    const svg = document.querySelector(".svg-container");
  const location = useLocation();
  svg.classList.add("hide")
scroll((progress) => {
  progressWheel.style.strokeDasharray = `${progress}, 1`;
  if(progress > 0.11){
  svg.classList.remove("hide")
  svg.classList.add("show")
  svg.classList.remove("d-none")
}
  else{
    svg.classList.add("hide")
    svg.classList.remove("show")
  }
});

const isPageActive = (path) => {
    return location.pathname === path;
  };

    const scrollToSection = (id) => {
      const section = document.querySelector(id);
      if (section) {
        const offsetTop = section.offsetTop;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth" // Pour un défilement fluide
        });
      }
    };



    return ( 
    // <div className="container">
    //     <div className="footer">
    //         <div className="row align-items-center m-auto">
    //             <div className="col-lg-6 col-12 mb-2">
    //                 <div className="logo">
    //                     <img src="/img/logo.svg" alt="logo teraalma" />
    //                 </div>
    //                 <div className="form">
    //                     <form action="">
    //                         <input type="text" placeholder="S'inscrire à notre newsletter" className="form-control" />
    //                         <Btn1 text='envoyer'/>
    //                     </form>
    //                 </div>
    //                 <div className="rs">
    //                     <img src="/img/icons/linkedin.svg" alt="" className='me-4' />
    //                     <img src="/img/icons/facebook.svg" alt="" />
    //                 </div>
    //             </div>
    //             <div className="col-lg-2 col-4 mb-2">
    //                 <div className="title"><h4>Salarié</h4></div>
    //                 <div className="content">
    //                     <ul>
    //                         <li>Titre 1</li>
    //                         <li>Titre 2</li>
    //                         <li>Titre 3</li>
    //                     </ul>
    //                 </div>
    //             </div>
    //             <div className="col-lg-2 col-4 mb-2">
    //             <div className="title"><h4>Salarié</h4></div>
    //                 <div className="content">
    //                     <ul>
    //                         <li>Titre 1</li>
    //                         <li>Titre 2</li>
    //                         <li>Titre 3</li>
    //                     </ul>
    //                 </div>
    //             </div>
    //             <div className="col-lg-2 col-4 mb-2">
    //             <div className="title"><h4>Salarié</h4></div>
    //                 <div className="content">
    //                     <ul>
    //                         <li>Titre 1</li>
    //                         <li>Titre 2</li>
    //                         <li>Titre 3</li>
    //                     </ul>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    <div className="footer">
         

        <div className="links">
            <ul>
                <li><Link to="/"  className={`nav-link ${isPageActive("/") ? 'active' : ''}`}
                                onClick={() => scrollToSection("/")}

                >Accueil</Link> </li>
                <li><Link to="/offres"  className={`nav-link ${isPageActive("/offres") ? 'active' : ''}`}
                                onClick={() => scrollToSection("/offres")}

                >Nos offres</Link> </li>
                <li><Link to="/carte-virtuelle"  className={`nav-link ${isPageActive("/carte-virtuelle") ? 'active' : ''}`}
                        onClick={() => scrollToSection("/carte-virtuelle")}
                >Carte virtuelle</Link> </li>
                <li><Link to="/contact"  className={`nav-link ${isPageActive("/contact") ? 'active' : ''}`}
                        onClick={() => scrollToSection("/contact")}
                        >Contactez-nous</Link> </li>
            </ul>
        </div>
        <div className="desc">
            <p>
            Teraalma c’est des réductions et des offres exclusives selectionnées pour vous par Happyness Ambassadors.
            </p>
        </div>
        <div className="copy">
            &copy; 2023 Teraalma.
        </div>
    </div>
     );
}

export default Footer;