import React, { useEffect } from 'react'

function Paiement({setStepValid , infos, setInfos}) {
    useEffect(() => {
        setStepValid(true)
    }, [])
    return ( <div className='py-4'>
        Nous allons vous rediriger vers la page de paiement
    </div> );
}

export default Paiement;