import React from "react";
import Card3 from "../cards/card3";

function TypeOffre() {
  return (
    <div className="typeoffre ">
      <div className="container">
        
        <div className="row">
            <div className="col-md-6">
                <Card3 title="Teraalma " classes="bg" />
            </div>
            <div className="col-md-6">
                <Card3 title="TerralMax" classes="sansBg" duree={2} />
            </div>
        </div>
      </div>
    </div>
  );
}

export default TypeOffre;
