import React from 'react'
import { Link } from 'react-router-dom';


function Card1({img="", title="", text="", href = "#"}) {
    return ( 
        <div className="card1 ">
        <Link to={href}>
            <div className="icon"><img src={"/img/"+img} alt="" /></div>
            <div className="content">
                <div className="title"><h3>{title}</h3></div>
                <div className="text"><p>{text} </p></div>
            </div>
            <div className="link">
                {/* <Link to={href}>Voir plus</Link> */}
                </div>

        </Link>
        </div>
     );
}

export default Card1;