import React, { useState } from 'react'
import Submit from '../../components/buttons/submit';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form"
import axios from 'axios';
import { loginUser } from '../../funtions/login';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
function Login() {
    const [hasError, setHasError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
const dispatch = useDispatch()
const navigate = useNavigate()
    const dataa = useSelector((data) =>  data)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()
    
      const onSubmit = (data) => {
        // const url = process.env.REACT_APP_BASE_API+process.env.REACT_APP_LOGIN
        // const formdata = new FormData()
        // formdata.append("username", data.username)
        // formdata.append("password", data.password)
        // axios.post(url, formdata)
        //     .then((res) => {
        //         console.log(res)
        //     })
        //     .catch((err) => console.log(err))
        // const test = loginUser(data.username, data.password, dispatch)
        // navigate("/")
        // console.log(test)

        async function handleLogin() {
            try {
              const is_connected = await loginUser(data.username, data.password, dispatch);
              // is_connected contiendra les données renvoyées par le serveur
                if(is_connected === true)
                navigate("/dashboard")
                else{
                    setHasError(true)
                    setErrorMessage(is_connected.response.data.errorMessage)
                }
            } catch (error) {
              console.error("Erreur lors de la connexion :", error);
              // Gérez l'erreur, par exemple, affichez un message d'erreur à l'utilisateur
            }
          }

          handleLogin()
      }


    return ( <div className="login">
        
        <div className="form">
        <div className="title">
            <h3>Se connecter</h3>
            <p>Renseigner vos informations</p>
        {hasError && <Alert key="danger" variant="danger">{errorMessage}</Alert>}
        </div>
            <form  onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <label htmlFor="username">Numéro carte</label>
                        <input type="text" name='username' className="form-control" placeholder='Email' {...register("username" , { required: true })} />
                    </div>
                    <div className="col-md-12 mb-4">
                        <label htmlFor="password">Mot de passe</label>
                        <input type="password" name='password' className="form-control" placeholder='Mot de passe' {...register("password" , { required: true })} />
                    </div>
                    <div className="col-12">
                    <Submit text="Se connecter" bg='#FFE0A7' color='#C95105' />
                    </div>
                </div>
            </form>
            <div className="link mt-4 text-center">
                <span>Vous n'avez pas de compte ? <Link to="/auth/register">S'inscrire</Link></span>
            </div>
        </div>
    </div> );
}

export default Login;