import Header from './components/header';
import Footer from './components/sections/footer';
import HeaderSectionn from './components/sections/headerSection';
import OffreSection from './components/sections/offreSection';
import PresentationSection from './components/sections/presentationSection';
import Process from './components/sections/process';
import TypeOffre from './components/sections/typeOffre';

function App() {


  return (
    <>
    <div className='section1 full'>
      <Header />
      <HeaderSectionn />
    </div>
      <PresentationSection />
      <Process />
      <OffreSection />
      <div className="sectionHeader mt-5">
          <div className="title">
            <div className="content">
              <h2>Nos types de carte</h2>
              <p className='text-primary'> 
                Obtenez une carte et profitez de nos réductions.
              </p>
            </div>
          </div>
        </div>
    <div className='lastSection'>
      <TypeOffre />
      <Footer />
      </div>
      
    </>
      );
}

export default App;
