import { Alert } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';

function InfoPerso({setStepValid , infos, setInfos}) {

      const [hasError, setHasError] = useState(false)
      const [errorMessage, setErrorMessage] = useState("")
      const [changeState, setChangeState] = useState(false)
      useEffect(() => {
        validate(infos) 
      }, [changeState] )
      const addInfo = (field, value) => {
        setInfos(prevInfos => ({
            ...prevInfos,   
            [field]: value
          }));
          setChangeState(!changeState)

      }
      const validate = (infos) => {
        if (
            ! infos.hasOwnProperty("prenom") ||
            ! infos.hasOwnProperty("nom") ||
            ! infos.hasOwnProperty("username") ||
            ! infos.hasOwnProperty("tel") ||
            ! infos.hasOwnProperty("password") ||
            ! infos.hasOwnProperty("passwordconfirm") 
        ){
            setHasError(true)
            setErrorMessage("Veuillez renseigner tous les champs")
            setStepValid(false)
            return
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(! emailRegex.test(infos.username)){
            setHasError(true)
            setErrorMessage("email incorrect")
            setStepValid(false)
            return
        }else{
          const data = {
            username : infos.username
          }
          axios.post(process.env.REACT_APP_BASE_API +
            process.env.REACT_APP_VERIF_EMAIL, data)
            .then((res) => {
              console.log(res.data)
            }).catch((err) => {
              setErrorMessage(err.response.data.error)
              setStepValid(false)
              setHasError(true)

            })
        }
        if(infos.password !== infos.passwordconfirm){
            setHasError(true)
            setErrorMessage("Les mots de passe ne correspondent pas")
            setStepValid(false)
            return
        }
        setHasError(false)
        setStepValid(true)

      }
        return ( 
        <form  >
            
        {hasError && <Alert  severity="warning" className='mb-2'>{errorMessage}</Alert>}
          <div className="row">
            <div className="col-md-6 mb-4">
              <label htmlFor="prenom">
                Prénom{" "}
                <span className="text-danger">
                  <sup>*</sup>
                </span>{" "}
              </label>
              <input
                type="text"
                name="prenom"
                className="form-control"
                placeholder="Prénom"
                defaultValue={infos.prenom}
                onChange={e => addInfo("prenom", e.target.value)}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="nom">
                Nom{" "}
                <span className="text-danger">
                  <sup>*</sup>
                </span>{" "}
              </label>
              <input
                type="text"
                name="nom"
                className="form-control"
                placeholder="Nom"
                defaultValue={infos.nom}
                onChange={e => addInfo("nom", e.target.value)}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="Email">
                Email{" "}
                <span className="text-danger">
                  <sup>*</sup>
                </span>{" "}
              </label>
              <input
                type="email"
                name="Email"
                className="form-control"
                placeholder="Email"
                defaultValue={infos.username}
                onChange={e => addInfo("username", e.target.value)}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="tel">
                Téléphone{" "}
                <span className="text-danger">
                  <sup>*</sup>
                </span>{" "}
              </label>
              <input
                type="text"
                name="tel"
                className="form-control"
                placeholder="Téléphone"
                defaultValue={infos.tel}
                onChange={e => addInfo("tel", e.target.value)}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="password">
                Mot de Passe{" "}
                <span className="text-danger">
                  <sup>*</sup>
                </span>{" "}
              </label>
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Mot de Passe"
                defaultValue={infos.password}
                onChange={e => addInfo("password", e.target.value)}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="confirm">
                Confirmer le mot de passe{" "}
                <span className="text-danger">
                  <sup>*</sup>
                </span>{" "}
              </label>
              <input
                type="password"
                name="confirm"
                className="form-control"
                placeholder="Confirmer le mot de passe"
                defaultValue={infos.passwordconfirm}
                onChange={e => addInfo("passwordconfirm", e.target.value)}
              />
            </div>
          </div>
        </form>
     );
}

export default InfoPerso;