import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { hasUser } from '../redux/userSlice';

function HasUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(hasUser({}));

        // Vérifiez si is_auth est vrai après que l'action ait été dispatchée
        if (data.is_auth === true) {
          // L'utilisateur est connecté
          // Vous pouvez effectuer des actions spécifiques ici
          console.log("Connecté");
        } else {
          // L'utilisateur n'est pas connecté
          // Vous pouvez effectuer des actions spécifiques ici
          console.log("Non connecté");
        }
      } catch (error) {
        console.error('Une erreur s\'est produite :', error);
        // Gérer l'erreur, par exemple, afficher un message d'erreur à l'utilisateur.
      }
    };

    fetchData();
  }, [dispatch, data.is_auth, navigate]);
  

  return data.is_auth ? <Outlet /> : null;
}

export default HasUser;
