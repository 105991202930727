import React from 'react'

function Condition({options, setOptions}) {
    return ( <div className="use-condition">
        <p>
        En utilisant l'application Terralma, vous acceptez nos conditions d'utilisation suivantes :

L'application Terralma vous permet de profiter d'offres spéciales dans différents établissements comme des hôtels, des restaurants et plus encore via des cartes munies de QR codes. En téléchargeant et en utilisant notre application, vous acceptez intégralement ces conditions. <br />

Les cartes Terralma sont destinées à un usage personnel uniquement et ne doivent en aucun cas être revendues ou utilisées à des fins commerciales sans autorisation. <br />

Les offres de réduction disponibles via les cartes Terralma sont soumises aux conditions spécifiques de chaque établissement partenaire. Avant de réaliser une transaction, veuillez consulter les détails de l'offre. <br />

Vous êtes responsable de l'utilisation appropriée de l'application Terralma et des cartes associées. Toute utilisation frauduleuse ou abusive entraînera la résiliation de vos droits d'accès. <br />

Nous accordons une grande importance à la protection de vos données personnelles. En utilisant Terralma, vous consentez à notre politique de confidentialité qui décrit la collecte, l'utilisation et le stockage de vos informations. <br />

Terralma se réserve le droit de modifier ces conditions à tout moment. Votre utilisation continue de l'application constitue votre acceptation des nouvelles conditions. <br />

Pour toute question ou demande d'assistance concernant Terralma, n'hésitez pas à nous contacter à l'adresse indiquée dans la rubrique "Contact" de l'application. <br />

En utilisant Terralma, vous bénéficiez de manière pratique et avantageuse des offres exclusives de nos partenaires. Nous vous remercions de votre confiance et de votre utilisation responsable de notre application.

        </p>
        <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => setOptions(prevOptions => ({
                  ...prevOptions,  
                  condition: !options.condition, 
                }))}
                checked={options.condition}
                />
  <label class="form-check-label" for="flexSwitchCheckChecked">Accepter les conditions d'utilisation</label>
</div>
    </div> );
}

export default Condition;