import React from "react";
import Btn2 from "../../components/buttons/button2";
import Btn1 from "../../components/buttons/button1";
import Logout from "../../funtions/logout";
import MobileMenu from "../../components/sections/mobileMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import WhatsApp from "../../components/buttons/whatsApp";

function TopBar() {
  return (
    <div className="topbar d-flex justify-content-between align-items-center">
    <div className="logo">
      <img src="/img/logo.svg" alt="" />
    </div>
    
    <div className="d-flex">
    <div className="iconn me-3">
      <WhatsApp />{" "}
    </div>
      <div className="d-none d-md-block">
        <Logout text="Se déconnecter" color="#000" />
      </div>
      <div className="d-block d-md-none">
        <MobileMenu />
      </div>
    </div>
  </div>
  );
}

export default TopBar;
