import React from 'react'
import Header from '../../components/header';
import SingleHeader from '../../components/sigleHeader';
import Index from '../auth/registerSteep';
import Footer from '../../components/sections/footer';
import FormContact from '../contact/form';

function VirtualCarte() {
    return ( 
        <div className="virtualCard ">
            <div className="section1">
            <Header/>
            <SingleHeader title='Carte Virtuelle' desc="Créez une carte virtuelle et profitez de nos offres promotionnelles exclusives." />
            </div>
            <div className="container mb-5">
                <Index isVirtual={true} horientation='horizontal' />
            </div>
            <div className="lastSection">
                <div className="text-center"><h2>Plus d'informations</h2></div>
                <FormContact/>
                <Footer />
            </div>
        </div>
     );
}

export default VirtualCarte;