import React from 'react'
import Header from '../../components/header';
import SingleHeader from '../../components/sigleHeader';
import FormContact from '../contact/form';
import Footer from '../../components/sections/footer';
import OffreSection from '../../components/sections/offreSection';

function OffrePage() {
    return ( 
        <div className="offres ">
        <div className="section1">
        <Header/>
        <SingleHeader title='Nos  Offres' desc="Souriez on vous rend heureux !."/>
        </div>
        <div>
            <OffreSection limit={true} />
        </div>
        <div className="lastSection">
            <Footer />
        </div>
    </div>
     );
}

export default OffrePage;