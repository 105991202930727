import { TextField } from '@mui/material';
import React, { useState } from 'react';

const Uploadimage = ({setLogo, id}) => {
    
    const [imageSize, setImageSize] = useState(40)
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        
        setLogo(file);
        const imgPreview = document.getElementById(`imagePreview-${id}`);
    
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                imgPreview.src = e.target.result;
                setImageSize(100)
                imgPreview.style.display = "block";
            };
            reader.readAsDataURL(file);
        } else {
            imgPreview.src = "";
            imgPreview.style.display = "none";
        }
      };
    return (
        <div>
            <input variant="outlined" fullWidth type="file" id={`imageproduit-${id}`} accept="image/*"  name={`imageproduit-${id}`} onChange={handleFileInputChange} style={{ display: 'none' }}/>
                    <div className='produit' onClick={() => document.getElementById(`imageproduit-${id}`).click()} style={{}}>
                        <img src="/img/defaultimg.png" alt="icone" className='iconeproduit'
                            id={`imagePreview-${id}`} style={{ maxWidth: `200px`, maxHeight: `200px`, borderRadius:"5px"}}
                            // id={`imagePreview-${id}`} style={{ maxWidth: `${imageSize}%`, maxHeight: `${imageSize}%`,margin:"auto",alignItems : 'center',borderRadius:"10px"}}
                        />
                    </div>
        </div>
    );
};

export default Uploadimage;