import React, { useEffect, useState } from 'react'
import TitleSection from '../titleSection';
import axios from 'axios';
import { Alert } from '@mui/material';

function IndexOffre() {
    const [offres, setOffres] = useState([])
    useEffect(() => {
        const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
        axios.get(
            process.env.REACT_APP_BASE_API +
              process.env.REACT_APP_OFFRE_LISTE, config
          ).then((res) => {
            console.log(res.data)
            setOffres(res.data)
          })

    }, [])
    return ( <div className="off">
         <TitleSection text='Liste des offres' textBtn='Ajouter' href='/dashboard/offres/new' /> 

         <div className="tableau mt-5">
        {offres?.length > 0 ? (
          <div className="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Partenaire </th>
                <th scope="col">Taux</th>
                <th scope="col">Date début</th>
                <th scope="col">Date fin</th>
              </tr>
            </thead>
            <tbody>
              {offres?.map((item, index) => (
                <tr>
                  <th scope="row">{index + 1} </th>
                  <td>{item?.partenaire}</td>
                  <td>{item?.tauxReduction} %</td>
                  <td>{item?.datedebut} </td>
                  <td>{item?.datefin} </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        ) : (
          <Alert severity="warning">Aucune  offre</Alert>
        )}
      </div>
    </div> );
}

export default IndexOffre;