import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

function Btn2({href="#", text="Bouton 1", color = "text-primary"}) {
    return ( <a href={href}>
        <button className={"btn ms-3 btn-2 "+color} >{text}
        <FontAwesomeIcon className={'ms-2 m-auto '+color} icon={faArrowRightLong} />
        </button>
    </a> );
}

export default Btn2;