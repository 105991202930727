import { Alert } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AddReduction from "../components/addReduction";

function VerifCard() {
  const [info, setInfo] = useState({});
  // const { cardId } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const nom = queryParams.get("nom");
  const status = queryParams.get("status");
  const type = queryParams.get("type");
  const numero = queryParams.get("numero");
  const active_at = queryParams.get("active_at");
  const end_at = queryParams.get("end_at");

  console.log(status);

  // useEffect(() => {
  //   axios
  //     .get(
  //       process.env.REACT_APP_BASE_API +
  //         process.env.REACT_APP_CARTE_INFO +
  //         cardId
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       if(res.data.status === false)
  //         window.location.href = "/auth/register"
  //       setInfo(res.data);
  //     });
  // }, []);
  return (
    <div className="statut-card">
      <div className="verif-card ">
        <div>
          <div className="content ">
            <div className="text">
              <div className="tout">
                <div className="icon mb-2">
                  <AddReduction numero={numero} />
                  {/* {status ? (
                    <img src="/img/ok-icon.svg" alt="Abonnement en cours" />
                  ) : (
                    <img src="/img/inactive.svg" alt="Abonnement en cours" />
                  )} */}
                </div>
                <div className="title">
                  <h6>
                    {/* {status ? "Abonnement en cours" : "Abonnement expiré"} */}
                  </h6>
                </div>
                <ul className="ul1">
                  <li>
                    <span>N° de la carte:</span>
                    <span className="span2">{numero}</span>
                  </li>

                  <li>
                    <span>Nom Complét:</span>
                    <span className="span2">{nom}</span>
                  </li>
                  <li>
                    <span>Type:</span>
                    <span className="span2">{type}</span>
                  </li>
                  <li>
                    <span>Activé le : </span>
                    <span className="span2">{active_at}</span>
                  </li>
                  <li>
                    <span>Expire le:</span>
                    <span className="span2">{end_at}</span>
                  </li>
                  {/* <li>
                    <span>CNI/Passport : </span>
                    <span className="span2">{info.cni}</span>
                  </li> */}
                </ul>

                {status ? (
                  <Alert severity="success">
                    Carte active
                  </Alert>
                ) : (
                  <Alert severity="danger">
                    Carte inactive
                  </Alert>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifCard;
