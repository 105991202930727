import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/auth/login";
import Auth from "./pages/auth";
import Register from "./pages/auth/register";
import { Provider } from "react-redux";
import { userStore } from "./redux/userSlice";
import Index from "./pages/dashbord";
import Abonnement from "./pages/dashbord/abonnement";
import MaCarte from "./pages/dashbord/macarte";
import NewAbonnement from "./pages/dashbord/newabonnement/newabonnement";
import IndexOffre from "./pages/dashbord/offres";
import IndexPartennaire from "./pages/dashbord/partenaires";
import NewPartenaire from "./pages/dashbord/partenaires/new";
import NewOffre from "./pages/dashbord/offres/new";
import VerifCard from "./pages/verifCard";
import AddCarte from "./pages/dashbord/addCarte";
import Contact from "./pages/contact/contact";
import VirtualCarte from "./pages/virtualCarte";
import OffrePage from "./pages/offres";
import PartenaireClient from "./pages/dashbord/partenaires/clients";
import ListPartenaire from "./pages/dashbord/partenaires/list";
import ClientList from "./pages/dashbord/clients";
import Error404 from "./pages/dashbord/404";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    loader: () => {
      return "loadinng";
    },
    children: [
      {
        path: "",
        element: <App />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/offres",
        element: <OffrePage />,
      },
      {
        path: "/carte-virtuelle",
        element: <VirtualCarte />,
      },
      {
        path: "userinfo/",
        element: <VerifCard />,
      },
      {
        path: "auth",
        element: <Auth />,
        children: [
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "register",
            element: <Register />,
          },
        ],
      },
      {
        path: "dashboard",
        element: <Index />,
        children: [
          {
            path: "mes-abonnements",
            element: <Abonnement />,
          },
          {
            path: "mes-abonnements/new",
            element: <NewAbonnement />,
          },
          {
            path: "ma-carte",
            element: <MaCarte />,
          },
          {
            path: "ma-carte/new",
            element: <AddCarte />,
          },
          {
            path: "offres",
            element: <IndexOffre />,
          },
          {
            path: "offres/new",
            element: <NewOffre />,
          },
          {
            path: "partenaires",
            element: <IndexPartennaire />,
          },
          {
            path: "partenaires/list",
            element: <ListPartenaire />,
          },
          {
            path: "partenaires/clients",
            element: <PartenaireClient />,
          },
          {
            path: "partenaires/new",
            element: <NewPartenaire />,
          },

          {
            path: "clients/list",
            element: <ClientList />,
          },
        ],
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={userStore}>
    <RouterProvider router={router} />
  </Provider>
);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
