import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function InfoAbonnement() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <a  onClick={handleShow} className='text-dark ' style={{cursor : "pointer", textDecoration: "underline"}}>
        <small>Plus d'informations</small>
      </a> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Teraalma ou Teraalma Max?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        
      </Modal>
    </>
  );
}

export default InfoAbonnement;