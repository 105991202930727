import React, { useEffect, useState } from 'react'
import TitleSection from '../titleSection';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function NewOffre() {
  const user = useSelector((state) => state.user);
  const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()



      const [partenaires, setPartenaires] = useState([])
      const navigate = useNavigate()
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
    useEffect(() => {
        axios.get(
            process.env.REACT_APP_BASE_API +
              process.env.REACT_APP_PARTENAIRE_LISTE , config
          ).then((res) => {
            console.log(res.data)
            setPartenaires(res.data)
          })

    }, [])

    const onSubmit = (data) => {
        const formData = new FormData()
        formData.append("tauxReduction", data.taux)
        formData.append("datedebut", data.start)
        formData.append("datefin", data.end)
        formData.append("description", data.description)
        formData.append("partenaireId", parseInt(data.partenaire) )

        console.log(data)
        // return
        const configg = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

        axios.post(
            process.env.REACT_APP_BASE_API +
              process.env.REACT_APP_OFFRE_NEW+user.email,
             formData, configg
          ).then((res) => {
            navigate("/dashboard/offres")
          })
      }

    return ( <div className="new-offre">

<TitleSection text='Ajouter une offre' textBtn='retour' href='/dashboard/offres' /> 

<div className="content mt-5">
    <div className="form">
        <form  onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
        <div className="col-md-6 mb-4">
            <label htmlFor="nom">Partenaire <span className='text-danger'><sup>*</sup></span></label>
            <select class="form-select" aria-label="Default select example"  {...register("partenaire" , { required: true })} >
                <option selected>Séléctionner un partenaire</option>
                {
                    partenaires?.map((item) =>(
                        <option value={item.id}>{item.nom} </option>
                    ))
                }
            </select>
        </div>
        <div className="col-md-6 mb-4">
            <label htmlFor="nom">Taux de réduction <span className='text-danger'><sup>*</sup></span></label>
            <input type="number" step={0.2} name='nom' className="form-control"  {...register("taux" , { required: true })} />
        </div>

        <div className="col-md-6 mb-4">
            <label htmlFor="nom">Date début <span className='text-danger'><sup>*</sup></span></label>
            <input type="date" name='nom' className="form-control"  {...register("start" , { required: true })} />
        </div>
        <div className="col-md-6 mb-4">
            <label htmlFor="nom">Date fin <span className='text-danger'><sup>*</sup></span></label>
            <input type="date" name='nom' className="form-control"  {...register("end" , { required: true })} />
        </div>
        <div className="col-md-12">
            <label htmlFor="nom">Description <span className='text-danger'><sup>*</sup></span></label>
            <textarea type="date" name='nom' className="form-control mb-4"  {...register("description" , { required: true })} />
        </div>
        <div className="col-md-12 text-end">
                        <button className="btn btn-1" type='submit'>Ajouter</button>
                        </div>
        </div>
        </form>
    </div>
</div>

    </div> );
}

export default NewOffre;