import { Alert } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'

function NumberCarte({setStepValid , infos, setInfos}) {
    const [isValid, setIsValid] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Veuillez mettre votre numéro de carte de 7 caractères")
    useEffect(() => {
        infos.numero && axios.get(process.env.REACT_APP_BASE_API+process.env.REACT_APP_VERIF_CARTE+infos.numero)
        .then((res) => {
            setIsValid(true)
            setStepValid(true)
            setInfos(prevInfos => ({
                ...prevInfos,   
                numeroCarte: infos.numero
              }));

        })
        .catch((err) => {
            setStepValid(false)
            setIsValid(false)
            setErrorMessage(err.response.data.message)
        })
    }, []);
    const verif = (number) => {
        number.length >= 7 &&
        axios.get(process.env.REACT_APP_BASE_API+process.env.REACT_APP_VERIF_CARTE+number)
            .then((res) => {
                
                setIsValid(true)
                setStepValid(true)
                setInfos(prevInfos => ({
                    ...prevInfos,   
                    numeroCarte: number
                  }));

            })
            .catch((err) => {
                setStepValid(false)
                setIsValid(false)
                setErrorMessage(err.response.data.message)
        })
    }
    return ( 
    <>
     {!isValid && <Alert severity="error" variant="outlined" className='mb-2 py-0'>{errorMessage}</Alert>}
    <input type="text" className='form-control' placeholder='numéro de votre carte' defaultValue={infos.numeroCarte} onChange={e => verif(e.target.value) } /> 
    </>
    );
}

export default NumberCarte;