import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Link } from 'react-router-dom';

function Btn1({href="#", text="Bouton 1", displayIcon=false}) {
    return ( <Link to={href}>
        
        <button className="btn btn-1">
            {displayIcon &&
        <FontAwesomeIcon className={'me-2 m-auto '} icon={faCreditCard} />
    }

            {text}</button>
    </Link> );
}

export default Btn1;