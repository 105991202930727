import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import Btn1 from "../../components/buttons/button1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";
import TitleSection from "./titleSection";

function MaCarte() {
  const user = useSelector((state) => state.user);
  const [maCarte, setMaCarte] = useState({});
  useEffect(() => {
    console.log(user)
    const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
    axios
      .get(
        process.env.REACT_APP_BASE_API +
          process.env.REACT_APP_QRCODE,
        config
      )
      .then((res) => {
        console.log(res.data);
        setMaCarte(res.data);
      });
  }, []);
  const handleDownloadClick = () => {
    const maCarteElement = document.querySelector(".ma-carte");

    if (maCarteElement) {
      html2canvas(maCarteElement).then((canvas) => {
        // Convertir le canvas en URL de données (base64)
        const dataURL = canvas.toDataURL("image/png");

        // Créer un élément "a" pour télécharger l'image
        const a = document.createElement("a");
        a.href = dataURL;
        a.download = "ma_carte.png"; // Nom du fichier à télécharger

        // Simuler un clic sur l'élément "a" pour déclencher le téléchargement
        a.click();
      });
    }
  };
  return (
    <div className="">
         {/* <TitleSection text='Activer une carte physique' textBtn='Ajouter' href='/dashboard/ma-carte/new' />  */}

    <div className="d-flex align-items-center justify-content-around flex-wrap mt-4">
        <div className="card-info d-block d-md-none mb-2">
        {maCarte?.isCardActive ? (
          <Alert severity="success">Votre carte est active</Alert>
        ) : (
          <Alert severity="error">Votre carte n'est pas active</Alert>
        )}
      </div>
      <div className="">

        <div className="ma-carte  shadow-lg">
            <div className="info shadow-lg ">
            <img
                src={`data:image/png;base64, ${maCarte?.qrCode} `}
                alt="Qrcode" 
              />
              <div className="text-center"><small>Carte N°{maCarte?.cardNumber}</small></div>
            </div>
            
        </div>
        <div className="card-footer text-center">
                <div className="text-uppercase">{maCarte?.prenom} {maCarte?.nom}</div>
            
            <button className="btn download w-100 mt-3" onClick={handleDownloadClick}> <FontAwesomeIcon icon={faDownload} /> Télécharger ma carte </button>

            </div>
      </div>

      <div className="card-info d-none d-md-block">
        {maCarte?.isCardActive ? (
          <Alert severity="success">Votre carte est active</Alert>
        ) : (
          <Alert severity="error">Votre carte n'est pas active</Alert>
        )}
      </div>
      
    </div>
    </div>
  );
}

export default MaCarte;
