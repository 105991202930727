import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Btn1 from '../buttons/button1';
import React from 'react'

function Card3({title="", classes="", duree = 1}) {
    return ( 
        <div className="card3">
            <div className="img">
                <img src="/img/card2.png" alt="Photo de la card" />
            </div>
            <div className={"content "+classes}>
                <div className="title">
                    <h3>{title}</h3>
                </div>
                <div className="text">
                    <ul>
                        {
                            duree === 1 ?
                        <li><FontAwesomeIcon className='icon me-2' icon={faCheck} /> <span>10 000 F valable 12 mois</span>  </li>
                        :
                        <li><FontAwesomeIcon className='icon me-2' icon={faCheck} /> <span>20 000 F valable 24 mois</span>  </li>
                        }
                    </ul>
                </div>
                <div className="text-center mybtn">
            <Btn1 text='Obtenir ma carte' href='/carte-virtuelle' />
                </div>
            </div>
        </div>
     );
}

export default Card3;