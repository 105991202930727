import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import { motion, AnimatePresence } from "framer-motion";
import Btn1 from "./buttons/button1";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const location = useLocation();

  const menuItems = [
    {
      href : "/",
      text : "Accueil"
    },
    {
      href : "/offres",
      text : "Nos offres"
    },
    {
      href : "/carte-virtuelle",
      text : "Carte virtuelle"
    },
    {
      href : "/contact",
      text : "Contactez-nous"
    },
  ];

  const isPageActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary mb-3">
        <Container >
          <Navbar.Brand href="/" className="me-2"><img src="/img/logo.svg" alt="Logo Téralma" /></Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          />
          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
            placement="end"
            backdrop={false}
            className={`offcanvas-menu  justify-content-end  ${
              showOffcanvas ? "show-offcanvas" : ""
            }`}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title><img src="/img/logo.svg" alt="Logo Téralma" /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="justify-content-between align-items-center">
              <div className="menu">
              <AnimatePresence>
              {menuItems.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.3 , delay: index * 0.1 }}
                >
                  <Link to={item.href}  className={`nav-link ${isPageActive(item.href) ? 'active' : ''}`}>{item.text}</Link>
                </motion.div>
              ))}

              </AnimatePresence>
              </div>
              <div>
                <Btn1 href="/auth/login" text="Se Connecter" />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

    </>
  );
}

export default Header;
