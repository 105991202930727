import React from 'react'
import Card1 from '../cards/card1';

function Process() {
    return ( 
        <div className="process">
        <div className="container">
            <div className="d-flex justify-content-around ">
                <div className=""> 
                <Card1 img='phone.svg' title='Ayez une carte virtuelle' text='Recevez une carte virtuelle et bénéficiez dès à présent de nos offres promotionnelles exclusives.' href='carte-virtuelle' /> 
                </div>
                <div className="">
                <Card1 img='card.svg' title='Enregistrez votre carte physique' text='Possédez-vous déjà une carte Teraalma ? Enregistrez-la en quelques clics et accédez à nos offres avantageuses immédiatement.' href='auth/register' /> 

                </div>
                {/* <div className="col-lg-4">
                <Card1 img='paie.svg' title='Obetenez votre reduction' text='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora iure ipsam deb' /> 

                </div> */}
            </div>
        </div>
        </div>
     );
}

export default Process;