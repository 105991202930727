import React from 'react'
import { useSelector } from 'react-redux';

function Error404() {
  const user = useSelector((state) => state.user);
  return ( <>
        <div className="error404">
            <div>
            <h1>404</h1>
            <p>Page introuvable. <br />
                Cette page a été supprimée ou déplacée.
            </p>

            
                <a href="https://teraalma.com/auth/login">
                <button className="btn btn-1">retour au site</button>
                </a>
            </div>
            </div>
    </> );
}

export default Error404;