import Accordion from 'react-bootstrap/Accordion';
import Submit from './buttons/submit';
import { useState } from 'react';
import { loginUser } from '../funtions/login';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert } from '@mui/material';

function AddReduction({numero = null}) {
    const [email, setEmail] = useState("")
    const [password, setPassoword] = useState("")
    const [hasError, setHasError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

   
    const  applyRection = (num) => {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
        axios.post(process.env.REACT_APP_BASE_API + process.env.REACT_APP_APPLY_REDUCTION,{
          "numero" : num
        }, config)
        .then((res) =>{
          setSuccess(true)
            console.log(res)
        }).catch((err) => {
            console.log(err)
            setHasError(true)
            setErrorMessage(err.response.data.message)
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(password)

        async function handleLogin() {
            try {
              const is_connected = await loginUser(email, password, dispatch);
              // is_connected contiendra les données renvoyées par le serveur
                if(is_connected === true)
                {
                    console.log("okey")
                applyRection(numero)
            }
                else{
                    setHasError(true)
                    setErrorMessage("Email ou mot de passe incorrect")
                }
            } catch (error) {
              console.error("Erreur lors de la connexion :", error);
              // Gérez l'erreur, par exemple, affichez un message d'erreur à l'utilisateur
            }
          }

          handleLogin()
    }
  return (
    <>
      {!success ?
      <Accordion >
      <Accordion.Item eventKey="1">
        <Accordion.Header>Teraalko</Accordion.Header>
        <Accordion.Body style={{maxWidth : "100%"}}>
        {hasError && <Alert key="danger" severity="error" variant="danger">{errorMessage}</Alert>}

          <form onSubmit={handleSubmit}>
            <input type="email" className="form-control" placeholder='identifiant' onChange={e => setEmail(e.target.value)} />
            <input type="password" className="form-control" placeholder='mot de passe'  onChange={e => setPassoword(e.target.value)} />
            <Submit text="Terralko" bg='#FFE0A7' color='#C95105' />

          </form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
      :
      <>
      <Alert severity="success">Teraal appliqué avec success</Alert>
      <a href="https://partenaire.teraalma.com/dashboard/partenaires/clients">
      <button className='btn btn-sm btn-primary px-2 mt-1'>Retour</button>
      </a>
      </>
      
      }


      </>

    
  );
}

export default AddReduction;