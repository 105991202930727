import axios from "axios"
import { addUser } from "../redux/userSlice"

export const loginUser = async (username, password, dispatch = () => {}) => {
    const url = process.env.REACT_APP_BASE_API+process.env.REACT_APP_LOGIN
        const formdata = new FormData()
        formdata.append("username", username)
        formdata.append("password", password)
        return axios.post(url, {"username" : username, "password" : password})
            .then((res) => {
                // console.log(res.data)
                dispatch(addUser(res.data))
                // console.log("oe")
                return true
            })
            .catch((err) => {return err})
  }