import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import TitleSection from "./titleSection";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";

function AddCarte() {
    const [errorMessage, setErrorMessage] = useState(false)
  const user = useSelector((state) => state.user);
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const formData = new FormData()
      formData.append("numeroCarte", data.number)
      axios.put(
        process.env.REACT_APP_BASE_API +
          process.env.REACT_APP_ADD_CARTE+user.email,
         formData, config
      ).then((res) => {
        navigate("/dashboard/ma-carte")
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message)
      })
  }
  return (
    <div className="addCarte">
         <TitleSection text='Retour ' textBtn='Retour' href='/dashboard/ma-carte/' /> 
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        
        <div className="col-md-6 mb-4">
        {errorMessage &&
          <Alert severity="error">{errorMessage}</Alert>
        }
          <label htmlFor="nom">
            Numéro de la carte
            <span className="text-danger">
              <sup>*</sup>
            </span>
          </label>
          <input
            type="text"
            name="nom"
            className="form-control"
            {...register("number", { required: true })}
          />
        </div>
        <div className="col-md-6 ">
          <button className="btn btn-1" type="submit">
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddCarte;
