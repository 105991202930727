import { faChevronLeft, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
function Auth() {
    const location = useLocation()
    const navigate = useNavigate()
    console.log(location)
    useEffect(() => {
        if(location.pathname === "/auth/"){
            navigate("/auth/login")
        }
    }, [])
    return (
        <div className="auth">
        <div className="row">
            <div className="col-md-7 p-0">
                <div className="illustration">
                    <div className="container p-4">
                        <Link to="/"><small><FontAwesomeIcon icon={faLongArrowAltLeft} className='me-2' />Retour au site </small></Link>
                    </div>
                    {/* <img src="/img/hand.png" alt="login illustration" /> */}
                </div>
            </div>
            <div className="col-md-5 p-0"> <Outlet /> </div>
        </div>
        </div> 
     );
} 

export default Auth;