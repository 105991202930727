import React, { useState } from "react";
import Submit from "../../components/buttons/submit";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../redux/userSlice";
import jwtDecode from "jwt-decode";
import { loginUser } from "../../funtions/login";
import Index from "./registerSteep";

function Register() {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const data = useSelector((data) => data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  console.log(data);
  const onSubmit = (data) => {
    const url = process.env.REACT_APP_BASE_API + process.env.REACT_APP_REGISTER;
    // dispatch(addUser({}))
    if (validPassword(data.password, data.confirm)) {
      if (validEmail(data.username)) {
        const formdata = new FormData();
        formdata.append("prenom", data.prenom);
        formdata.append("nom", data.nom);
        formdata.append("username", data.email);
        formdata.append("password", data.password);
        formdata.append("passwordconfirm", data.passwordconfirm);
        formdata.append("telephone", data.tel);
        formdata.append("cni", data.cni);
        axios
          .post(url, data)
          .then((res) => {
            async function handleLogin() {
              try {
                const is_connected = await loginUser(data.username, data.password, dispatch);
                // is_connected contiendra les données renvoyées par le serveur
                  if(is_connected === true)
                  navigate("/dashboard")
                  else{
                      setHasError(true)
                      setErrorMessage(is_connected.response.data.errorMessage)
                  }
              } catch (error) {
                console.error("Erreur lors de la connexion :", error);
                // Gérez l'erreur, par exemple, affichez un message d'erreur à l'utilisateur
              }
            }
  
            handleLogin()
        }
          )
          .catch((err) => {
            setHasError(true);
            setErrorMessage(err.response.data.errorMessage);
          });
      } else {
        setHasError(true);
        setErrorMessage("Veillez mettre une adresse email valide");
      }
    } else {
      setHasError(true);
      setErrorMessage("Les mots de passe ne correspondent pas");
    }
  };

  function validPassword(password1, password2) {
    return true;
    return password1 === password2;
  }
  function validEmail(email) {
    // Expression régulière pour valider un email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Utilise la regex pour vérifier si l'email est valide
    return emailRegex.test(email);
  }

  return (
    <div className="login">
      <div className="form">
        <div className="title">
          <h3>S'inscrire</h3>
          <p>Renseigner vos informations</p>
          {hasError && (
            <Alert key="danger" variant="danger">
              {errorMessage}
            </Alert>
          )}
        </div>
        
        <Index />
        <div className="link mt-4 text-center">
          <span>
            Avez-vous déjà un compte ?{" "}
            <Link to="/auth/login">Se connecter</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Register;
