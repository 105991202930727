import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function WhatsApp() {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Discutons sur whatsApp
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Button variant="" className='p-2 text-white rounded ' style={{background : "#25d366" }}> <a href="https://wa.me/00221780199494" style={{color : "inherit", textDecoration : "none"}}><FontAwesomeIcon icon={faWhatsapp}  className=''/></a> </Button>
    </OverlayTrigger>
  );
}

export default WhatsApp;