import React from 'react'
import Header from '../../components/header';
import SingleHeader from '../../components/sigleHeader';
import Footer from '../../components/sections/footer';
import FormContact from './form';

function Contact() {
    return ( 
        <div className="contact ">
            <div className="section1">
            <Header/>
            <SingleHeader title='Contactez - Nous' desc="Vous pouvez nous joindre sur nos différents plateformes."/>
            </div>
            <div className="lastSection">
                <div className="title text-center">
                        <h4>Vous avez une question ?</h4>
                        <p>discutons-en directement sur <a href="https://wa.me/221780199494">whatsApp</a></p>

                </div>
                <FormContact/>
                <Footer />
            </div>
        </div>
     );
}

export default Contact;