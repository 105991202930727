import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function ChoixOffre({options, setOptions}) {
  return (
    <div className="mt-5 choix-offre">

      <div class="form-check d-flex ">
        <div className="info d-flex ">
          <div className="text ">
            <div>
              <div className="type">
                <p>TERAALMA 6 mois</p>
              </div>
              <div className="prix">
                <h4>10 000F</h4>
              </div>
            </div>
            <div>
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDisabled"
                id="flexRadioDisabled1"
                checked={options.dureeAbonnement === "mois"}
                onClick={() => setOptions(prevOptions => ({
                  ...prevOptions,  
                  dureeAbonnement:"mois", 
                }))}

              />
              <label class="form-check-label" for="flexRadioDisabled1">
                Choisir
              </label>
            </div>
          </div>
          <div className="img">
            <img src="/img/carte3.svg" alt="image" />
          </div>
        </div>
        <div className="detailles ms-5 align-items-center d-flex">
          <div className="title"> 
            <h3>6 mois</h3>
          </div>
          <div className="list">
            {/* <ul>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} /> item 1{" "}
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} /> item 1{" "}
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} /> item 1{" "}
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      <div class="form-check d-flex mt-5">
        <div className="info d-flex ">
          <div className="text ">
            <div>
              <div className="type">
                <p>TERAALMA 1 an</p>
              </div>
              <div className="prix">
                <h4>15 000 F</h4>
              </div>
            </div>
            <div>
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDisabled"
                checked={options.dureeAbonnement === "an"}
                id="flexRadioDisabled13"
                onClick={() => setOptions(prevOptions => ({
                  ...prevOptions,  // Copiez toutes les propriétés existantes de l'objet options
                  dureeAbonnement: "an",         // Modifiez la propriété "type" avec la nouvelle valeur (dans cet exemple, 1)
                }))}

              />
              <label class="form-check-label" for="flexRadioDisabled13">
                Choisir
              </label>
            </div>
          </div>
          <div className="img">
            <img src="/img/carte3.svg" alt="image" />
          </div>
        </div>
        <div className="detailles ms-5 align-items-center d-flex">
          <div className="title">
            <h3>1 an</h3>
          </div>
          <div className="list">
            {/* <ul>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} /> item 1{" "}
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} /> item 1{" "}
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} /> item 1{" "}
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoixOffre;
