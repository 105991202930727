import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../redux/userSlice';

function Logout({href="#", text="Bouton 1", displayIcon=false}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogout = () => {
        dispatch(logout())
        navigate("/")
    }
    return ( <Link to={href}>
        
        <button className="btn btn-1" onClick={handleLogout}>
            {displayIcon &&
        <FontAwesomeIcon className={'me-2 m-auto '} icon={faCreditCard} />
    }

            {text}</button>
    </Link> );
}

export default Logout; 