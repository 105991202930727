import { faAdversal } from '@fortawesome/free-brands-svg-icons';
import { faBars, faGift, faMoneyBillTrendUp, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';
import SiteBare from '../../pages/dashbord/sitebare';
import Logout from '../../funtions/logout';

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="btn btn-1 me-2">
        <FontAwesomeIcon icon={faBars} />
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="mobile-menu">
        <ul>
                <NavLink to="/dashboard/ma-carte" onClick={handleClose} isActive={false} activeClassName="active"> <li> <FontAwesomeIcon icon={faQrcode} className='me-2' /> Ma carte</li></NavLink>
                <NavLink to="/dashboard/mes-abonnements" onClick={handleClose} isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faMoneyBillTrendUp} className='me-2' /> Mes Abonnements</li></NavLink>
                <NavLink to="/dashboard/partenaires" onClick={handleClose} isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faGift} className='me-2' />  Partenaires</li></NavLink>
                <NavLink to="/dashboard/offres" onClick={handleClose} isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faAdversal} className='me-2' />  Offres</li></NavLink>
        </ul>
    </div> 
    <Logout text="Se déconnecter" color="#000"  />

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function MobileMenu() {
  return (
    <>
      {['start'].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

