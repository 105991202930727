import React, { useEffect, useState } from "react";
import Card2 from "../cards/card2";
import axios from "axios";
import Card4 from "../cards/card4";

function OffreSection({limit = true}) {
  const [offres, setOffres] = useState([])
  // useEffect(() => {
  //     if(!limit){
  //       axios.get(
  //         process.env.REACT_APP_BASE_API +
  //           process.env.REACT_APP_OFFRE_LISTE, 
  //       ).then((res) => {
          
  //         setOffres(res.data)
  //       })
  //     }else{
  //     axios.get(
  //         process.env.REACT_APP_BASE_API +
  //           process.env.REACT_APP_OFFRE_TOT_LISTE, 
  //       ).then((res) => {
          
  //         setOffres(res.data)
  //       })
  //     }

  // }, [])
  return (
    <div className="offresection ">
      <div className="container">
        {/* {limit && offres.length > 0 &&  */}
        <div className="sectionHeader">
          <div className="title">
            <div className="content">
              <h2>Nos top offres</h2>
              <p className="text-primary">
              Souriez on vous rend heureux !
              </p>
            </div>
          </div>
        </div>
        <div className="cardoffre">
          <div className="row text-center ">
            {/* {
              offres?.map((item, index) => (
                <div className="col-lg-4  col-md-6">
                  <Card2 taux={item.tauxReduction} partenaire={item.partenaire} description={item.description} logo={item.logo} />
                </div>
              ))
            } */}
             <div className="col-lg-4  col-md-6">
                  <Card2 taux={15} partenaire={"Yakhya Excursion"} description={"Avec Yakhya Excursions, explorez le Sénégal autrement. De certains coins de paradis inconnus aux lieux les plus emblématiques du pays, ce guide saura satisfaire votre envie de découvertes."} logo={"/img/yakhya.png"} />{" "}
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2  partenaire={"Desert de lompoul"} description={"..."} logo={"/img/desert.png"} />
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2  partenaire={"Hotel Colonia"} description={"..."} logo={"/img/colonia.png"} />
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2  partenaire={"Hôtel Pullman"} description={"..."} logo={"/img/pullman.png"} />
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2  partenaire={"Hôtel Radisson"} description={"..."} logo={"/img/radisson.png"} />
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2  partenaire={"Terrou Bi"} description={"..."} logo={"/img/teroubi.png"} />
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OffreSection;
