import axios from 'axios';
import React, { useEffect, useState } from 'react'

function PartenaireClient() {
    const [clients, setClients] = useState([])

    useEffect(() => {
        const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

        axios.get(process.env.REACT_APP_BASE_API + process.env.REACT_APP_CLIENT_LIST, config)
        .then((res) => {
            setClients(res.data.clients)
            // console.log(res.data.clients)
        })
    },[])
    return ( 
        <table class="table table-bordered table-sm">
  <thead class="table-primary">
    <tr>
      <th scope="col">N Carte</th>
      <th scope="col">Nom</th>
      <th scope="col">Tel</th>
      <th scope="col">Email</th>
      <th scope="col">Civility</th>
      <th scope="col">Fait le</th>
    </tr>
  </thead>
  <tbody>
    {
        clients.reverse().map((client) => (
            <tr>
                <th scope="row">{client.carte.number}</th>
                <td>{client.carte.user.name}</td>
                <td>{client.carte.user.tel}</td>
                <td>{client.carte.user.email}</td>
                <td>{client.carte.user.civility}</td>
                <td>{Intl.DateTimeFormat('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
 }).format(new Date(client.create_at))} </td>
            </tr>
        ))
    }
  </tbody>
</table>

     );
}

export default PartenaireClient;