import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Submit from '../../components/buttons/submit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Alert } from '@mui/material';
import { Spinner } from 'react-bootstrap';

function FormContact() {
    const [message, setMessage] = useState("")
    const [classe, setClasse] = useState("")
    const [success, setSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()
    
    const url = process.env.REACT_APP_BASE_API + process.env.REACT_APP_CONTACT;
    const onSubmit = (data) => {
        setIsLoading(true)
        axios.post(url, data).then((res) => {
            setSuccess(true)
            setClasse("success")
            setMessage("Message envoyé avec succès")
        setIsLoading(false)
    })
    }
    return ( 
        <div className="container">
            <div className="contact-form">
                <div className="row">
                    <div className="col-lg-8 left">
                        <div className="form">
                           <form onSubmit={handleSubmit(onSubmit)} >
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="prenom">Prénom</label>
                                    <input type="text" name='prenom' className="form-control mb-4" placeholder='Prénom' {...register("prenom" , { required: true })} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="nom">Nom</label>
                                    <input type="text" name='nom' className="form-control mb-4" placeholder='Nom' {...register("nom" , { required: true })} />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="tel">Téléphone</label>
                                    <input type="text" name='tel' className="form-control mb-4" placeholder='Téléphone' {...register("tel" , { required: true })} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name='email' className="form-control mb-4" placeholder='Email' {...register("email" , { required: true })} />
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="message">Message</label>
                                    <textarea type="email" name='message' className="form-control mb-4" placeholder='Message' {...register("message" , { required: true })} />
                                </div>
                                <div className="col-12">
                                    {
                                        success && <Alert severity={classe} className='mb-2'>{message}</Alert>
                                    }
                                 

                                    <button className="btn btn_submit" >Envoyer {isLoading && <Spinner animation="grow" />} </button>
                                </div>
                            </div>
                           </form>
                        </div>
                    </div>
                    <div className="col-lg-4 right">
                        <div className="rs">
                        <div className="title">
                            <span className='text'>Suivez nous</span>
                            {/* <span className="bar"></span> */}
                            <span className="icon">
                            <a href="https://wa.me/00221780199494" target='_blank'>
                            <FontAwesomeIcon icon={faWhatsapp} />
                                    </a> </span>
                        </div>
                        <div className="items">
                                <a href="https://wa.me/00221780199494" target='_blank'>
                            <div className="item">
                            <FontAwesomeIcon icon={faWhatsapp} /><span> +221 78 019 94 94</span>
                            </div>
                            </a>

                                    <a href="mailto:teraalma@teraalma.com" target='_blank'>
                            <div className="item">
                            <FontAwesomeIcon icon={faEnvelope} /><span> teraalma@teraalma.com</span>
                            </div>
                            </a>

                                    <a href="https://www.google.com/maps/@14.6941898,-17.4676864,15z?entry=ttu" target='blank'>
                            <div className="item">
                            <FontAwesomeIcon icon={faLocationDot} /> <span>Mamelles, Dakar</span>
                            </div>
                            </a>
                        </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormContact;